import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { provideEnvironmentNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { CustomerPaymentProfilesComponent } from './customer-payment-profiles/customer-payment-profiles.component'
import { PopoverModule, StatusSpinnerModule } from '@skykick/core';

@NgModule({
    declarations: [
        CustomerPaymentProfilesComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        StatusSpinnerModule,
        TranslateModule.forChild({
            extend: true
        }),
        NgxMaskDirective,
        NgxMaskPipe,
        PopoverModule
    ],
    providers: [
        provideEnvironmentNgxMask()
    ],
    exports: [
        CustomerPaymentProfilesComponent
    ]
})
export class CreditCardModule { }
